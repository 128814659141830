<template>
  <div style="flex: 1; background-color: #fff; padding: 10px">
    <el-select
        v-model="ta"
        placeholder="请选择"
        size="mini"
        style="margin-bottom: 10px"
    >
      <el-option
          v-for="item in formatCrowdOptions()"
          :key="item.value"
          :label="item.label"
          :value="item.value"
      >
      </el-option>
    </el-select>
    <div>
      <div
          v-for="(campaign, index) in campaignList"
          :key="campaign.campaignId"
          class="campaign_contain"
      >
        <el-card class="campaign_baseInfo">
          <p>{{ campaign.brand }}</p>
          <p>{{ campaign.campaignName }}</p>
          <p class="line"></p>
          <div>
            <svg-icon
                v-for="platform in campaign.platform"
                :key="platform"
                :class-name="platform"
                style="margin-right: 10px; font-size: 20px"
                :style="{ fill: fillColor(platform) }"
            />
          </div>
          <p class="base_info_item">
            <i class="el-icon-date"></i>
            {{ campaign.duration.replace(",", " 至 ") }}
          </p>
          <p class="base_info_item">
            <i class="el-icon-user-solid"></i>
            KOL数量：{{ campaign.kolNum }}
          </p>
          <p class="base_info_item">
            <i class="el-icon-document"></i>
            文章数量：{{ campaign.postNum }}
          </p>
          <p class="base_info_item">
            <i class="el-icon-shopping-cart-1"></i>
            总费用：￥{{ sliceDecimal(campaign.totalCost / 100) }}
          </p>
        </el-card>
        <el-card class="campaign_data">
          <div style="margin-bottom: 20px; display: flex; align-items: center">
            <el-radio-group
                v-model="campaigns[index].platform"
                size="mini"
                style="margin-right: 50px"
            >
              <el-radio-button
                  v-for="item in formatPlatform(campaign.platform)"
                  :key="item.value"
                  :label="item.value"
              >
                {{ item.label }}
              </el-radio-button>
            </el-radio-group>
            <el-select
                v-model="campaigns[index].agency"
                placeholder="请选择"
                size="mini"
            >
              <el-option
                  v-for="item in formatSupplier(campaign.agency)"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="date_contain">
            <div class="date_item">
              <p class="date_title">曝光数</p>
              <p class="date_value">
                {{ sliceDecimal(campaign.index.impression) }}
              </p>
            </div>
            <div class="date_item">
              <p class="date_title">互动数</p>
              <p class="date_value">
                {{ sliceDecimal(campaign.index.engagement) }}
              </p>
            </div>
            <div class="date_item">
              <p class="date_title">互动率</p>
              <p class="date_value">
                {{
                  campaign.index.engagementRate
                      ? campaign.index.engagementRate
                      : "N/A"
                }}
              </p>
            </div>
            <div class="date_item">
              <p class="date_title">CPI</p>
              <p class="date_value">
                {{ campaign.index.cpi ? campaign.index.cpi : "N/A" }}
              </p>
            </div>
            <div class="date_item">
              <p class="date_title">CPE</p>
              <p class="date_value">
                {{ campaign.index.cpe ? campaign.index.cpe : "N/A" }}
              </p>
            </div>
            <div class="date_item">
              <p class="date_title">CPTA</p>
              <p class="date_value">
                {{ campaign.index.cpta ? campaign.index.cpta : "N/A" }}
              </p>
            </div>
          </div>
        </el-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getCompareList } from "@/api/campaignTrace";

export default {
  name: "index",
  data() {
    return {
      campaignList: [],
      ta: "32_1",
      campaigns: [{ id: "", platform: "all", agency: "all" }],
    };
  },
  props: ["campaignArr"],
  computed: {
    ...mapGetters(["crowdList", "categorySeg"]),
    params() {
      return {
        ta: this.ta,
        campaigns: this.campaigns,
      };
    },
  },
  async mounted() {
    this.campaigns = this.campaignArr.map((item) => {
      return {
        id: item * 1,
        platform: "all",
        agency: "all",
      };
    });
  },
  methods: {
    fillColor(platform) {
      switch (platform) {
        case "wechat":
          return "#04BE02";
        case "weibo":
          return "#FF9933";
        case "douyin":
          return "#260e1f";
        case "xiaohongshu":
          return "#eb414c";
        case "bilibili":
          return "#fb7299";
      }
    },
    formatCrowdOptions() {
      return this.crowdList.map((v) => {
        return {
          value: v.key,
          label: v.name,
        };
      });
    },
    formatPlatform(platArr) {
      const res = platArr.map((item) => {
        return {
          label: this.platformToZn(item),
          value: item,
        };
      });
      res.unshift({ label: "全平台", value: "all" });
      return res;
    },
    formatSupplier(agencyArr) {
      const res = agencyArr
          ? agencyArr.map((item) => {
            return {
              label: item,
              value: item,
            };
          })
          : [];
      res.unshift({ label: "全部供应商", value: "all" });
      return res;
    },
    async getList() {
      const { data } = await getCompareList(this.params);
      this.campaignList = data;
    },
  },
  watch: {
    params: {
      deep: true,
      handler() {
        this.getList();
      },
    },
  },
};
</script>

<style scoped lang="scss">
.campaign_contain {
  margin-bottom: 10px;
  display: flex;

  .campaign_baseInfo {
    width: 280px;
    margin-right: 20px;

    div,
    p {
      height: 30px;
      line-height: 30px;
    }

    .line {
      height: 1px;
      border-top: 1px solid #ddd;
      margin: 10px 0;
    }

    .base_info_item {
      color: #aaa;
    }
  }

  .campaign_data {
    flex: 1;

    .date_contain {
      display: flex;
      flex-wrap: wrap;

      .date_item {
        flex: 0 0 16%;
        height: 90px;

        .date_title {
          color: #666;
          text-align: center;
          line-height: 26px;
        }

        .date_value {
          font-weight: bold;
          line-height: 30px;
          text-align: center;
        }
      }
    }
  }
}
</style>
